/**
 * one teaser for an article
 */

import React from 'react';
import {
  arrayOf, object, shape, string
} from 'prop-types';
import { Link } from 'react-router-dom';
import placeholder from '../../assets/placeholder.png';
import ImageRender from '../ImageRender';

import './style.scss';

const ArticleTeaser = ({
  title, size, urlPath, thumbnail, statusIcons
}) => (
  <Link to={urlPath.toLowerCase()} className={`article-teaser ${size}`}>
    <div className="status-wrapper">{statusIcons}</div>
    <div className="teaser-image-wrapper">
      <ImageRender image={thumbnail || placeholder} />
    </div>
    <div className="title-wrapper">
      <h3>{title}</h3>
    </div>
  </Link>
);

ArticleTeaser.propTypes = {
  size: string.isRequired,
  title: string.isRequired,
  urlPath: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  statusIcons: arrayOf(object),
  thumbnail: shape({
    shape: string,
    url: string
  }).isRequired
};

ArticleTeaser.defaultProps = {
  statusIcons: []
};

export default ArticleTeaser;
