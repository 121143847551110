import { shape, string } from 'prop-types';
import React from 'react';
import Spinner from '../Spinner';

import './style.scss';

const ImageRender = ({ alt, className, image }) => {
  if (!image.url) return <Spinner size="lg" />;
  return (
    <div className="image-container">
      <div className={`image-wrapper image-${image.shape}`}>
        <img
          alt={alt}
          className={`image-${image.shape} ${className}`}
          src={image.url}
        />
      </div>
    </div>
  );
};

ImageRender.propTypes = {
  alt: string,
  className: string,
  image: shape({
    shape: string,
    url: string
  })
};

ImageRender.defaultProps = {
  alt: 'article cover',
  className: '',
  image: {
    shape: '',
    url: ''
  }
};

export default ImageRender;
