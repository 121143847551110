/* eslint-disable react/prop-types */
import React from 'react';

import { v4 as uuid } from 'uuid';
import {
  arrayOf, bool, object, string
} from 'prop-types';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ArticleTeaser from '../ArticleTeaser';
import useCatalog from '../../hooks/useCatalog';
import Auth from '../../utils/Auth';

import './style.scss';

const ArticleTeaserListSideScroll = ({ size, teasers, toEditor }) => {
  const loggedIn = Auth.loggedIn();
  const { categories } = useCatalog();

  if (!teasers || !teasers.length) return 'no articles';
  return (
    <div className="snap-scroll-teaser-list">
      <div>
        {teasers.map(({
          _id, categoryId, title, thumbnail, isPublished
        }) => {
          const statusIcons = [];
          if (loggedIn) {
            statusIcons.push(
              <p key="publish-check">
                published:
                {' '}
                {isPublished ? (
                  <FaCheck color="green" />
                ) : (
                  <FaTimes color="red" />
                )}
              </p>
            );
          }

          return (
            <div key={uuid()}>
              <ArticleTeaser
                urlPath={`/${categories[categoryId]}/${_id}${
                  toEditor ? '/edit' : ''
                }`}
                statusIcons={statusIcons}
                thumbnail={thumbnail}
                title={title}
                size={size}
                key={_id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ArticleTeaserListSideScroll.propTypes = {
  size: string,
  // eslint-disable-next-line react/forbid-prop-types
  teasers: arrayOf(object).isRequired,
  toEditor: bool
};
ArticleTeaserListSideScroll.defaultProps = {
  size: 'sm',
  toEditor: false
};

export default ArticleTeaserListSideScroll;
