import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { STORE_UPDATE_APP_CONFIG_BIO_PIC, STORE_UPDATE_APP_CONFIG_BIO_TEXT, STORE_UPDATE_APP_CONFIG_BRAND_TEXT } from '../store/appConfig/actions';
import { GQL_UPDATE_APP_CONFIG_BIO_PIC_URL, GQL_UPDATE_APP_CONFIG_BIO_TEXT, GQL_UPDATE_APP_CONFIG_BRAND_TEXT } from '../utils/mutations';


const useAppConfigUtils = () => {
  const dispatch = useDispatch();
  const [_updateAppConfigBioPic] = useMutation(GQL_UPDATE_APP_CONFIG_BIO_PIC_URL);
  const [_updateAppConfigBioText] = useMutation(GQL_UPDATE_APP_CONFIG_BIO_TEXT);
  const [_updateAppConfigBrandText] = useMutation(GQL_UPDATE_APP_CONFIG_BRAND_TEXT);

  return {
    updateAppConfigBioPic: (appConfigId, shape, url) => new Promise((resolve, reject) => {
      if (!appConfigId || !shape || !url) {
        reject(new Error('missing parameters'));
        return;
      }

      _updateAppConfigBioPic(
        { variables: { input: { appConfigId, data: { shape, url } } } }
      ).then(({ data: { updateAppConfigBioPic: { success } } }) => {
        if (!success) reject(new Error('failed to update'));
        else {
          dispatch({
            type: STORE_UPDATE_APP_CONFIG_BIO_PIC,
            payload: { bioPic: { shape, url } }
          });
          resolve({ success });
        }
        reject(new Error('failed to update'));
      }).catch(reject);
    }),

    updateAppConfigBioText: (appConfigId, bioText) => new Promise((resolve, reject) => {
      if (!appConfigId || !bioText) {
        reject(new Error('missing parameters'));
        return;
      }

      _updateAppConfigBioText(
        { variables: { input: { appConfigId, bioText } } }
      ).then(({ data: { updateAppConfigBioText: { success } } }) => {
        if (!success) reject(new Error('failed to update'));
        else {
          dispatch({
            type: STORE_UPDATE_APP_CONFIG_BIO_TEXT,
            payload: { bioText }
          });
          resolve({ success });
        }
      }).catch(reject);
    }),

    updateAppConfigBrandText: (appConfigId, brandText) => new Promise((resolve, reject) => {
      if (!appConfigId || !brandText) {
        reject(new Error('missing parameters'));
        return;
      }
      _updateAppConfigBrandText(
        { variables: { input: { appConfigId, brandText } } }
      ).then(({ data: { updateAppConfigBrandText: { success } } }) => {
        if (!success) reject(new Error('failed to update'));
        else {
          dispatch({
            type: STORE_UPDATE_APP_CONFIG_BRAND_TEXT,
            payload: { brandText }
          });
          resolve({ success });
        }
      }).catch(reject);
    })
  };
};

export default useAppConfigUtils;
