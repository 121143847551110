// Creates a Redux store that holds the state of the app. Only one store should exist.
import { createStore, combineReducers, applyMiddleware } from 'redux';

import appConfig from './appConfig/reducers';
import articles from './articles/reducers';
import categories from './categories/reducers';
import middleware from './middleware';

export default createStore(
  combineReducers({
    appConfig,
    articles,
    categories
  }),
  applyMiddleware(middleware)
);
