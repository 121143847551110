import React from 'react';
import AboutMe from '../../components/AboutMe';
import ArticleRecentList from '../../components/ArticleRecentList';

import './style.scss';

const HomePage = () => (
  <>
    <ArticleRecentList />
    <AboutMe />
  </>
);

export default HomePage;
