import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GQL_GET_ARTICLES } from '../utils/queries';
import { STORE_SET_ARTICLES } from '../store/articles/actions';
import useReduxStore from './useReduxStore';
import Auth from '../utils/Auth';

const useArticleLoader = () => {
  const dispatch = useDispatch();
  const { categories } = useReduxStore();
  const { data, loading } = useQuery(GQL_GET_ARTICLES);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const loggedIn = Auth.loggedIn();

  useEffect(async () => {
    if (!categories) return;

    const articles = data?.getArticles;

    if (categories && articles) {
      const thing = dispatch({
        type: STORE_SET_ARTICLES,
        payload: { articles, categories }
      });

      if (thing.type) {
        setLoadingArticles(false);
      }
    }
  }, [data, loading, loggedIn, categories]);

  return { loadingArticles };
};

export default useArticleLoader;
