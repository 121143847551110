import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useReduxStore from '../../hooks/useReduxStore';
import AdminLoginForm from '../AdminLoginForm';

import HeaderBrand from './HeaderBrand';
import HeaderNav from './HeaderNav';
import './style.scss';

const Header = () => {
  const { categories } = useReduxStore();
  const [displayLoginForm, setDisplayLoginForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/');
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') setSelectedTab('/');
  }, [pathname]);

  if (!categories) return '';

  return (
    <header id="home">
      <HeaderBrand
        setSelectedTab={setSelectedTab}
        setDisplayLoginForm={setDisplayLoginForm}
        displayLoginForm={displayLoginForm}
      />
      <Collapse in={displayLoginForm}>
        <div id="wrapper-admin-login">
          <AdminLoginForm />
        </div>
      </Collapse>
      <Collapse in={!displayLoginForm}>
        <div id="wrapper-nav-items">
          {!pathname.includes('/edit')
            ? (
              <HeaderNav
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                categories={categories}
              />
            )
            : ''}
        </div>
      </Collapse>

    </header>
  );
};

export default Header;
