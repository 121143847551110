import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import {
  bool, func, object, oneOfType, string
} from 'prop-types';

import './style.scss';

const Button = ({
  id,
  className,
  children,
  disabled,
  onClick,
  size,
  type,
  variant
}) => (
  <BootstrapButton
    id={id}
    className={className}
    disabled={disabled}
    onClick={onClick}
    size={size}
    type={type}
    variant={variant}
  >
    {children}
  </BootstrapButton>
);

Button.propTypes = {
  id: string,
  children: oneOfType([string, object]).isRequired,
  className: string,
  disabled: bool,
  onClick: func,
  size: string,
  type: string,
  variant: string
};

Button.defaultProps = {
  id: '',
  className: '',
  disabled: false,
  onClick: () => null,
  size: '',
  type: 'button',
  variant: ''
};

export default Button;
