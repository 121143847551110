import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Col, Form, InputGroup, Row
} from 'react-bootstrap';
import useCategoryUtils from '../../hooks/useCategoryUtils';
import ArticleTeaserListSideScroll from '../../components/ArticleTeaserListSideScroll';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import useCatalog from '../../hooks/useCatalog';
import Auth from '../../utils/Auth';

import './style.scss';
import useArticleUtils from '../../hooks/useArticleUtils';

const AdminPage = () => {
  const navigate = useNavigate();
  const { categories, articles } = useCatalog();
  const [newCategoryName, setNewCategoryName] = useState('');
  const { createCategory, deleteCategory } = useCategoryUtils();
  const { createArticle } = useArticleUtils();
  const loggedIn = Auth.loggedIn();

  const handleCreateArticle = async (categoryId) => {
    const {
      article: { _id: articleId }
    } = await createArticle(categoryId);
    navigate(`/${categories[categoryId]}/${articleId}/edit`);
  };

  useEffect(() => {
    if (!loggedIn) navigate('/error');
  }, []);

  if (!loggedIn) return null;
  if (!categories || !articles) return <Loading />;
  return (
    <section className="admin-page">
      <Row className="mb-4">
        <Col className="d-grid gap-2 mb-4" sm={{ span: 12, offset: 0 }}>
          <Button type="submit" variant="outline-dark">
            <Link to="/admin/edit">Manage About Me</Link>
          </Button>
        </Col>
      </Row>

      {categories
        && Object.entries(categories).map(([_id, categoryName]) => {
          const teasers = Object.values(
            articles[categoryName.toLowerCase()] || []
          );
          return (
            <section key={_id}>
              <div className="admin-section-header">
                <div>
                  <h2>{categoryName}</h2>
                  {!teasers.length && (
                    <Button
                      className="py-0"
                      size="small"
                      variant="outline-danger"
                      onClick={async () => deleteCategory({ _id, categoryName })}
                    >
                      delete
                    </Button>
                  )}
                </div>
                <Button
                  className="py-0"
                  type="button"
                  variant="outline-dark"
                  onClick={async () => handleCreateArticle(_id)}
                >
                  new article
                </Button>
              </div>
              <hr />
              <div className="admin-section-teasers">
                <ArticleTeaserListSideScroll
                  size="sm"
                  toEditor
                  teasers={teasers}
                />
              </div>
            </section>
          );
        })}

      <Form
        onSubmit={async (event) => createCategory(newCategoryName, setNewCategoryName, event)}
      >
        <InputGroup className="admin-section-new-category">
          <Form.Control
            placeholder="New Category"
            aria-label="new article category"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            onSubmit={(e) => e.preventDefault()}
          />
          <Button
            type="submit"
            variant="outline-success"
            disabled={!newCategoryName}
          >
            Save
          </Button>
        </InputGroup>
      </Form>
    </section>
  );
};

AdminPage.propTypes = {};

export default AdminPage;
