import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const ErrorPage = () => (
  <div className="page-not-found">
    <h1>👩🏽‍💻</h1>
    <h2>this is awkward…</h2>
    <h6>[ 404 : Page Not Found ]</h6>
    <div>
      <Link to="/">Return Home</Link>
    </div>
  </div>
);

ErrorPage.propTypes = {};

export default ErrorPage;
