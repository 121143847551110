import React, { useState, useEffect } from 'react';
import {
  Form, Button, Alert, Row, Col
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useMutation } from '@apollo/client';
import { GQL_LOGIN_CONFIG } from '../../utils/mutations';

import Auth from '../../utils/Auth';

import './style.scss';

const AdminLoginForm = () => {
  const [userFormData, setUserFormData] = useState({
    email: '',
    password: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [loginAdmin, { error }] = useMutation(GQL_LOGIN_CONFIG);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
  }, [error]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const { data } = await loginAdmin({
      variables: { input: { ...userFormData } }
    });

    Auth.login(data.loginAdmin.token);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Alert
        dismissible
        onClose={() => setShowAlert(false)}
        show={showAlert}
        variant="danger"
      >
        Incorrect username or password
      </Alert>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Email"
            value={userFormData.email}
            onChange={handleInputChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={2}>
          Password
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            required
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleInputChange}
            value={userFormData.password}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} id="sign-in">
        <Col sm={{ span: 10, offset: 2 }}>
          <Button
            type="submit"
            variant="outline-dark"
            disabled={!(userFormData.email && userFormData.password)}
          >
            Sign in
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default AdminLoginForm;
