import { bool, shape, string } from 'prop-types';
import React, { useState } from 'react';

const SocialIcon = ({ icon }) => {
  const [width] = useState('50');
  const [{ alt, src, href }] = useState(icon);

  if (!icon.isPublished) return '';

  return (
    <a href={href} target="blank">
      <img src={src} alt={alt} width={width} />
    </a>
  );
};

SocialIcon.propTypes = {
  icon: shape({
    alt: string,
    href: string,
    src: string,
    isPublished: bool
  }).isRequired
};

export default SocialIcon;
