import React, { useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import HomePage from './pages/HomePage';
import CategoryPage from './pages/CategoryPage';
import Footer from './components/Footer';
import ArticleEditorPage from './pages/ArticleEditorPage';
import AdminPage from './pages/AdminPage';
import ArticlePage from './pages/ArticlePage';
import ErrorPage from './pages/ErrorPage';
import Loading from './components/Loading';
import useCategoryLoader from './hooks/useCategoryLoader';
import useArticleLoader from './hooks/useArticleLoader';
import useAppConfigLoader from './hooks/useAppConfigLoader';
import useReduxStore from './hooks/useReduxStore';
import AppConfigEditorPage from './pages/AppConfigEditorPage';
import Auth from './utils/Auth';

const App = () => {
  const { loadingAppConfig } = useAppConfigLoader();
  const { loadingCategories } = useCategoryLoader();
  const { loadingArticles } = useArticleLoader();
  const { categories } = useReduxStore();

  const loggedIn = Auth.loggedIn();

  const categoryRoutes = useMemo(() => {
    const categoriesArr = Object.entries(categories);

    if (loadingCategories || !categoriesArr.length) return '';
    return categoriesArr.map(([_id, name]) => (
      <Route
        key={_id}
        path={`/${encodeURI(name)}`}
        element={<CategoryPage categoryName={name} />}
      />
    ));
  }, [loadingCategories, categories]);

  const isLoading = () => {
    if (loadingAppConfig) return true;
    if (loadingCategories) return true;
    if (loadingArticles) return true;
    return false;
  };

  const getLoadingMessage = () => {
    if (loadingAppConfig || loadingCategories || loadingArticles) return <Loading />;
    return '';
  };

  return (
    <Router>
      <Header />
      <div className="page-wrapper">
        {isLoading() ? (
          getLoadingMessage()
        ) : (
          <Routes>
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/edit" element={<AppConfigEditorPage />} />

            {categoryRoutes}

            <Route path="/:categoryName/:articleId" element={<ArticlePage />} />
            {loggedIn && (
              <Route
                path="/:categoryName/:articleId/edit"
                element={<ArticleEditorPage />}
              />
            )}
            <Route path="/" element={<HomePage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        )}
      </div>
      <Footer />
    </Router>
  );
};

export default App;
