import React, { useState, useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import useArticleHtml from '../../hooks/useArticleHtml';
import RichTextRenderer from '../../components/RichTextRenderer';
import ImageRender from '../../components/ImageRender';

import './style.scss';

// when editor previews an article being edited, the __html is passed in
// this is being used to determine if a page is
// being displayed for a guest or admin
const ArticlePage = ({ editorState }) => {
  const { categoryName, articleId } = useParams();
  const location = useLocation();
  const { loading, article } = useArticleHtml({ categoryName, articleId });
  const [display, setDisplay] = useState(editorState);
  const navigate = useNavigate();

  const getTimestamps = () => {
    const timestamps = [];
    let updatedAt;

    if (editorState?.hasLocalUnpublishedChanges) {
      updatedAt = new Date().toLocaleDateString();
    } else if (display.updatedAt) {
      updatedAt = new Date(
        parseInt(display.updatedAt, 10)
      ).toLocaleDateString();
    }
    timestamps.push(
      `created: ${new Date(
        parseInt(display.createdAt, 10)
      ).toLocaleDateString()}`
    );

    if (updatedAt) {
      timestamps.push(`updated: ${updatedAt}`);
    }

    return timestamps.join(' | ');
  };


  useEffect(() => {
    if (!article) navigate('/error');
    if (location.pathname.includes('/edit')) {
      setDisplay(editorState);
      return;
    }

    setDisplay(article);
  }, [article]);

  if (loading) return <Loading />;

  return (
    <div className="article-full">
      <div className="article-title-wrapper">
        <h1>{display.title}</h1>
        <h6 className="date">{`[ ${getTimestamps()} ]`}</h6>
      </div>
      <div className="article-cover-image">
        <ImageRender
          image={display.thumbnail}
          alt="article cover"
        />
      </div>
      <hr />
      <RichTextRenderer
        text={display.text}
        html={display.html}
      />
    </div>
  );
};

ArticlePage.propTypes = {
  editorState: shape({
    createdAt: string,
    hasLocalUnpublishedChanges: bool,
    html: string,
    thumbnail: shape({
      shape: string,
      url: string
    }),
    title: string,
    updatedAt: string
  })
};

ArticlePage.defaultProps = {
  editorState: {
    createdAt: '',
    hasLocalUnpublishedChanges: true,
    html: '',
    title: '',
    updatedAt: ''
  }
};

export default ArticlePage;
