/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Col, Form, Row } from 'react-bootstrap';
import useEditorState, { EDITOR_VIEW } from '../../hooks/useEditorState';
import useReduxStore from '../../hooks/useReduxStore';
import Auth from '../../utils/Auth';
import ArticlePage from '../ArticlePage';
import FormGroupButton from '../../components/FormGroupButton';
import ImageEditor from '../../components/ImageEditor';
import RichTextEditor from '../../components/RichTextEditor';
import Spinner from '../../components/Spinner';
import { deleteLocalStorageItem, setLocalStorageItem } from '../../store_local';
import {
  GQL_UPDATE_ARTICLE_HTML,
  GQL_UPDATE_ARTICLE_THUMBNAIL
} from '../../utils/mutations';

import './styles.scss';
import useArticleUtils from '../../hooks/useArticleUtils';

const { EDITOR, PREVIEW } = EDITOR_VIEW;

const ArticleEditorPage = () => {
  const navigate = useNavigate();
  const { categoryName, articleId } = useParams();
  const { categories, articles } = useReduxStore();
  const [publishing, setPublishing] = useState(false);
  const {
    updateArticleCategoryId,
    updateArticleThumbnail,
    updateArticleTitle,
    updateArticleHTML,
    updateArticlePublished,
    deleteArticle
  } = useArticleUtils();
  const {
    state, setState, lsKeyArticleTitle, lsKeyTempHtml
  } = useEditorState({
    categoryName,
    articleId
  });

  const loggedIn = Auth.loggedIn();

  const handleTitleChange = ({ target: { value } }) => {
    setLocalStorageItem({ key: lsKeyArticleTitle, value });
    setState({ ...state, title: value });
  };
  const handleCategoryChange = ({ target: { value } }) => setState({ ...state, categoryId: value });
  const handleToggleView = (view) => setState({ ...state, view });
  const handlePublishSwitch = () => setState(
    (curr) => ({ ...curr, isPublished: !curr.isPublished })
  );

  const handlePublishChanges = async () => {
    if (!loggedIn) return;
    setPublishing(true);
    const promises = [];

    try {
      promises.push(updateArticleCategoryId({ article: state }));
      promises.push(updateArticleThumbnail({ article: state }));
      promises.push(updateArticleTitle({ article: state }));
      deleteLocalStorageItem({ key: lsKeyArticleTitle });
      promises.push(updateArticleHTML({ article: state }));
      deleteLocalStorageItem({ key: lsKeyTempHtml });
      promises.push(updateArticlePublished({ article: state }));

      await Promise.allSettled(promises);

      setPublishing(false);
      navigate('/admin');
    } catch (error) {
      // do something on error
      setPublishing(false);
      console.error(error);
    }
  };

  const handleDeleteArticle = async () => {
    const { success } = await deleteArticle({ articleId, categoryName });
    if (success) navigate('/admin');
  };

  const setStagedState = (thumbnail) => {
    setState({ ...state, thumbnail });
  };

  const handleClearLocalStorage = ({ key, name }) => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `\nReset ${name}?\n\nAll unsaved changes will be lost.`
    );
    if (!confirm) return;
    deleteLocalStorageItem({ key });
    window.location.reload();
  };

  const getUnsavedChangesAlert = (view) => {
    switch (view) {
      case EDITOR.NAME:
        if (state.hasLocalUnpublishedChanges) {
          return [
            {
              name: 'Title',
              lsKey: lsKeyArticleTitle,
              stateKey: 'title'
            },
            {
              name: 'Content',
              lsKey: lsKeyTempHtml,
              stateKey: 'html'
            }
          ].map(({ name, lsKey, stateKey }) => {
            if (
              state[stateKey] === articles[categoryName][articleId][stateKey]
            ) {
              return '';
            }
            return (
              <FormGroupButton
                key={stateKey}
                variant="outline-danger"
                onClick={() => handleClearLocalStorage({
                  name,
                  key: lsKey
                })}
              >
                {`${name} loaded from previous session, click to restore`}
              </FormGroupButton>
            );
          });
        }
        return '';

      default:
        return '';
    }
  };

  const getMainComponent = (view) => {
    switch (view) {
      case EDITOR.NAME:
        return (
          <>
            <Form>
              <ImageEditor
                label="Cover Photo"
                originalState={state.thumbnail}
                stagedState={state.thumbnail}
                setStagedState={setStagedState}
              />

              <Form.Group
                as={Row}
                controlId="formArticleTitle"
                className="mb-3"
              >
                <Col xs={2}>
                  <Form.Label>Title</Form.Label>
                </Col>
                <Col xs={10}>
                  <Form.Control
                    className="article-title"
                    type="text"
                    value={state.title || ''}
                    onChange={handleTitleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                controlId="formArticleCatecory"
                className="mb-3"
              >
                <Col xs={2}>
                  <Form.Label>Category</Form.Label>
                </Col>
                <Col xs={10}>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleCategoryChange}
                  >
                    <option>{categories[state.categoryId]}</option>
                    {Object.entries(categories)
                      .filter(([categoryId]) => categoryId !== state.categoryId)
                      .map(([_id, name]) => (
                        <option key={_id} value={_id}>
                          {name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Form>
            <RichTextEditor
              state={state}
              setState={setState}
              lsKeyTempHtml={lsKeyTempHtml}
              className="mb-3"
            />
          </>
        );

      case PREVIEW.NAME:
        return <ArticlePage editorState={state} />;

      default:
        return <p>default hit</p>;
    }
  };

  const getTopButton = (view) => {
    switch (view) {
      case EDITOR.NAME:
        return (
          <FormGroupButton onClick={() => handleToggleView(PREVIEW.NAME)}>
            Preview
          </FormGroupButton>
        );
      case PREVIEW.NAME:
        return (
          <FormGroupButton onClick={() => handleToggleView(EDITOR.NAME)}>
            Back to Editor
          </FormGroupButton>
        );

      default:
        return <p>default hit</p>;
    }
  };

  const getPublishRadios = (view) => {
    switch (view) {
      case PREVIEW.NAME:
        return (
          <Form.Group as={Row} className="mb-3">
            <Col xs={{ span: 4, offset: 8 }}>
              <Form.Switch
                type="switch"
                id="publish-no"
                name="publish-article"
                label="Publish Article"
                checked={state.isPublished === true}
                onChange={handlePublishSwitch}
              />
            </Col>
          </Form.Group>
        );

      default:
        return '';
    }
  };

  const getPublishButtonText = (isPublished) => {
    if (publishing) return <Spinner variant="light" />;

    switch (!!isPublished) {
      case true:
        return 'Publish';
      case false:
        return 'Save Draft';

      default:
        return '';
    }
  };

  const getBottomButton = (view) => {
    switch (view) {
      case EDITOR.NAME:
        return (
          <FormGroupButton
            variant="outline-danger"
            onClick={handleDeleteArticle}
          >
            Delete
          </FormGroupButton>
        );
      case PREVIEW.NAME:
        return (
          <FormGroupButton
            variant="outline-success"
            onClick={handlePublishChanges}
          >
            {getPublishButtonText(state.isPublished)}
          </FormGroupButton>
        );

      default:
        return '';
    }
  };

  return (
    <div className="editor-page">
      {getUnsavedChangesAlert(state.view)}
      {getMainComponent(state.view)}

      <Form className="px-3">
        {getTopButton(state.view)}
        {getPublishRadios(state.view)}
        {getBottomButton(state.view)}
      </Form>
    </div>
  );
};

ArticleEditorPage.propTypes = {};

export default ArticleEditorPage;
