/* eslint-disable react/prop-types */
import { string } from 'prop-types';
import React from 'react';
import ReactQuillEditor from 'react-quill';
// import { sanitize } from 'dompurify';
import { setLocalStorageItem } from '../../store_local';

import config from './config';

const { modules, formats } = config;
const editorDefaultValue = '<p><br></p>';

// eslint-disable-next-line react/prop-types
const RichTextEditor = ({
  className, state, setState, lsKeyTempHtml
}) => {
  const handleHTMLChange = (html) => {
    // ignore default values
    if (html === editorDefaultValue) return;

    try {
      setLocalStorageItem({ key: lsKeyTempHtml, value: html });
    } catch (error) {
      console.warn('image too large, unable to autosave html');
    }

    setState({
      ...state,
      html
    });
  };

  return (
    <div
      id="wrapper-quill"
      className={className}
    >
      <ReactQuillEditor
        modules={modules}
        formats={formats}
        value={state.html}
        onChange={handleHTMLChange}
        placeholder="hey there honey"
      />
    </div>
  );
};

RichTextEditor.propTypes = {
  className: string
};

RichTextEditor.defaultProps = {
  className: ''
};

export default RichTextEditor;
