/* eslint-disable jsx-a11y/label-has-associated-control */
import { func, shape, string } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import Button from '../Button';
import ImagePicker from '../ImagePicker';
import Spinner from '../Spinner';
import getCroppedImg from './cropImage';

import './style.scss';

const CROP_SHAPE_RECTANGLE = 'rect';
const CROP_SHAPE_ROUND = 'round';

const DEFAULT_CROP = { x: 0, y: 0 };
const DEFAULT_ZOOM = 1;
const DEFAULT_ROTATION = 0;

const ImageEditor = ({
  label, originalState, stagedState, setStagedState
}) => {
  const [imageCanvas, setImageCanvas] = useState(stagedState.url);
  const [crop, setCrop] = useState(DEFAULT_CROP);
  // eslint-disable-next-line no-unused-vars
  const [cropShape, setCropShape] = useState(stagedState.shape);
  const [rotation, setRotation] = useState(DEFAULT_ROTATION);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetZoom = () => setZoom(DEFAULT_ZOOM);
  const resetRotation = () => setRotation(DEFAULT_ROTATION);
  const resetCrop = () => setCrop(DEFAULT_CROP);
  const resetCropShape = () => setCropShape(originalState.shape);

  const imageIsOriginal = () => !(
    stagedState.shape !== cropShape
    || DEFAULT_CROP.x !== crop.x
    || DEFAULT_CROP.y !== crop.y
    || DEFAULT_ROTATION !== rotation
    || DEFAULT_ZOOM !== zoom
    || stagedState.url !== imageCanvas
  );

  const handleRoundedImageSwitch = () => {
    if (cropShape === CROP_SHAPE_RECTANGLE) {
      setCropShape(CROP_SHAPE_ROUND);
      return;
    }
    setCropShape(CROP_SHAPE_RECTANGLE);
  };


  const onCropComplete = useCallback((croppedArea, areaPixels) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const handleResetChanges = () => {
    resetCrop();
    resetZoom();
    resetRotation();
    resetCropShape();
    setImageCanvas(originalState.url);
  };

  const handleApplyChanges = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImage = await getCroppedImg(
        imageCanvas,
        croppedAreaPixels,
        rotation
      );
      setStagedState({
        shape: cropShape,
        url: croppedImage || stagedState.url
      });
      resetZoom();
      resetRotation();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [croppedAreaPixels, rotation, cropShape]);

  useEffect(() => {
    setImageCanvas(stagedState.url);
  }, [stagedState]);

  if (!imageCanvas || !stagedState.url || !originalState.url) { return <Spinner />; }

  return (
    <div className="image-editor">
      <Form.Group as={Row} controlId="formPictureShape">
        <Col xs={3}>
          <Form.Label>{label}</Form.Label>
        </Col>

        <Col xs={3}>
          <Form.Switch
            id="rounded-image-switch"
            label="Circle"
            checked={cropShape === CROP_SHAPE_ROUND}
            onChange={handleRoundedImageSwitch}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formPictureSelector">
        <Col sm={12}>
          <ImagePicker setState={setImageCanvas} />
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        className="crop-container mb-3"
        controlId="formPictureCanvas"
      >
        <Cropper
          image={imageCanvas}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          cropShape={cropShape}
          aspect={4 / 4}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          zoomWithScroll
        />
      </Form.Group>

      <Form.Group as={Row} className="" controlId="formPictureZoom">
        <Col xs={2}>
          <Form.Label>Zoom</Form.Label>
        </Col>

        <Col xs={8}>
          <Form.Control
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            name="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="mb-3"
          />
        </Col>

        <Col xs={2}>
          <Form.Control
            type="button"
            value="reset"
            onClick={resetZoom}
            variant="outline-dark"
            size="sm"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="" controlId="formPictureRotation">
        <Col xs={2}>
          <Form.Label>Rotation</Form.Label>
        </Col>

        <Col xs={8}>
          <Form.Control
            type="range"
            value={rotation}
            min={-180}
            max={180}
            step={0.1}
            name="rotation"
            onChange={(e) => {
              setRotation(e.target.value);
            }}
            className="mb-3"
          />
        </Col>

        <Col xs={2}>
          <Form.Control
            type="button"
            value="reset"
            onClick={resetRotation}
            variant="outline-dark"
            size="sm"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="" controlId="formPictureRotation">
        <Col xs={6} className="d-grid gap-2">
          <Button
            type="button"
            variant="outline-dark"
            onClick={handleResetChanges}
            disabled={imageIsOriginal()}
          >
            Reset
          </Button>
        </Col>

        <Col xs={6} className="d-grid gap-2">
          <Button
            type="button"
            variant="outline-success"
            onClick={handleApplyChanges}
            disabled={imageIsOriginal()}
          >
            {loading ? <Spinner /> : 'Save'}
          </Button>
        </Col>
      </Form.Group>

    </div>
  );
};

ImageEditor.propTypes = {
  label: string.isRequired,
  stagedState: shape({
    shape: string,
    url: string
  }).isRequired,
  originalState: shape({
    shape: string,
    url: string
  }).isRequired,
  setStagedState: func.isRequired
};

export default ImageEditor;
