import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { STORE_SET_APP_CONFIG } from '../store/appConfig/actions';
import { GQL_GET_APP_CONFIG } from '../utils/queries';

const useAppConfigLoader = () => {
  const dispatch = useDispatch();
  const { loading: loadingAppConfig, data } = useQuery(GQL_GET_APP_CONFIG);
  const appConfig = data?.appConfig;

  useEffect(() => {
    if (loadingAppConfig || !appConfig) return;

    dispatch({
      type: STORE_SET_APP_CONFIG,
      payload: { ...appConfig }
    });
  }, [loadingAppConfig, appConfig]);

  return { loadingAppConfig };
};

export default useAppConfigLoader;
