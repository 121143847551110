/**
 * data access pattern
 *
 * store.categories[_id]
 *
 * store: {
 *    ...,
 *    categories: {
 *        [_id]: [name]
 *    }
 * }
 *
 * */
import { STORE_CREATE_CATEGORY, STORE_DELETE_CATEGORY, STORE_SET_CATEGORY } from './actions';

const defaultState = {};


const formatCategoryAccessPattern = (arr) => arr.reduce(
  (acc, { _id, name }) => ({
    ...acc,
    [_id]: name
  }),
  {}
);

// eslint-disable-next-line default-param-last
const categoriesReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case STORE_SET_CATEGORY: {
      return {
        ...state,
        ...formatCategoryAccessPattern(payload.categories)
      };
    }
    case STORE_CREATE_CATEGORY: {
      const { category: { _id, name } } = payload;
      return {
        ...state,
        [_id]: name
      };
    }
    case STORE_DELETE_CATEGORY: {
      const { _id } = payload;
      const copy = { ...state };
      delete copy[_id];
      return { ...copy };
    }

    default:
      return state;
  }
};

export default categoriesReducer;
