import { string } from 'prop-types';
import React, { useMemo } from 'react';
import ArticleTeaserListSideScroll from '../../components/ArticleTeaserListSideScroll';
import useCatalog from '../../hooks/useCatalog';
import './style.scss';

const CategoryPage = ({ categoryName }) => {
  const { articles } = useCatalog({ categoryName });

  const teasers = useMemo(
    () => (!articles ? [] : Object.values(articles)),
    [articles, categoryName]
  );
  if (!categoryName) return 'no categoryName';

  return !teasers.length ? (
    <h3 className="no-articles">no articles at this time</h3>
  ) : (
    <div className="category-page">
      <h2>{categoryName}</h2>
      <ArticleTeaserListSideScroll
        size="md"
        teasers={teasers}
      />
    </div>
  );
};

CategoryPage.propTypes = {
  categoryName: string.isRequired
};

export default CategoryPage;
