export const CONFIG_AUTH_TOKEN = 'authToken';
export const LS_SELECTED_PAGE = 'selectedPage';
export const LS_SELECTED_BLOG_ID = 'selectedBlogId';

export const LS_PAGE_HOME = 'HOME';
export const LS_PAGE_BOOKS = 'BOOKS';
export const LS_PAGE_LIFESTYLE = 'LIFESTYLE';
export const LS_PAGE_TRAVEL = 'TRAVEL';

export const LS_AUTO_SAVE_BLOG = 'autoSaveBlog';

const LS_KEY = 'racquelfox.com';

const getLocalStorage = () => JSON.parse(localStorage.getItem(LS_KEY)) || {};
const setLocalStorage = (object) => localStorage.setItem(LS_KEY, JSON.stringify(object));

export const setLocalStorageItem = ({ key, value }) => {
  const ls = { ...getLocalStorage(), [key]: value };
  setLocalStorage(ls);
};

export const getLocalStorageItem = ({ key }) => {
  const ls = getLocalStorage();
  return ls[key] || null;
};

export const deleteLocalStorageItem = ({ key }) => {
  const ls = getLocalStorage();
  delete ls[key];
  setLocalStorage(ls);
};

export const destroyLocalStorageData = () => {
  localStorage.removeItem(LS_KEY);
};
