import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  STORE_CREATE_ARTICLE,
  STORE_UPDATE_ARTICLE,
  STORE_DELETE_ARTICLE
} from '../store/articles/actions';
import {
  GQL_CREATE_ARTICLE,
  GQL_DELETE_ARTICLE,
  GQL_UPDATE_ARTICLE_CATEGORY_ID,
  GQL_UPDATE_ARTICLE_HTML,
  GQL_UPDATE_ARTICLE_PUBLISHED,
  GQL_UPDATE_ARTICLE_THUMBNAIL,
  GQL_UPDATE_ARTICLE_TITLE
} from '../utils/mutations';
import useCatalog from './useCatalog';

const useArticleUtils = () => {
  const dispatch = useDispatch();
  const { categories } = useCatalog();
  const [_createArticle] = useMutation(GQL_CREATE_ARTICLE);
  const [_updateArticleCategoryId] = useMutation(GQL_UPDATE_ARTICLE_CATEGORY_ID);
  const [_updateArticleThumbnail] = useMutation(GQL_UPDATE_ARTICLE_THUMBNAIL);
  const [_updateArticleTitle] = useMutation(GQL_UPDATE_ARTICLE_TITLE);
  const [_updateArticleHTML] = useMutation(GQL_UPDATE_ARTICLE_HTML);
  const [_updateArticlePublished] = useMutation(GQL_UPDATE_ARTICLE_PUBLISHED);

  const [_deleteArticle] = useMutation(GQL_DELETE_ARTICLE);

  const handleDefaultUpdateApiCall = ({
    article,
    method,
    methodName,
    hasRequiredParams,
    input
  }) => new Promise((resolve, reject) => {
    if (!hasRequiredParams) {
      reject(new Error('missing params'));
      return;
    }
    method({ variables: { input } })
      .then(({ data: { [methodName]: { success } } }) => {
        if (!success) {
          reject(new Error('failure to update'));
          return;
        }
        dispatch({
          type: STORE_UPDATE_ARTICLE,
          payload: { categoryName: categories[article.categoryId], article }
        });
        resolve({ success });
      }).catch(reject);
  });


  return {
    createArticle: (categoryId) => new Promise((resolve, reject) => {
      if (!categoryId) {
        reject(new Error('missing categoryId'));
        return;
      }
      _createArticle({
        variables: { input: { categoryId } }
      }).then(({ data: { createArticle: { article } } }) => {
        if (!article) {
          reject(new Error('failed to create'));
          return;
        }
        dispatch({
          type: STORE_CREATE_ARTICLE,
          payload: { article, categoryName: categories[categoryId] }
        });
        resolve({ article });
      }).catch(reject);
    }),

    updateArticlePublished: ({ article }) => {
      const { _id: articleId, isPublished } = article;
      return handleDefaultUpdateApiCall({
        article,
        method: _updateArticlePublished,
        methodName: 'updateArticlePublished',
        hasRequiredParams: (articleId && typeof isPublished === 'boolean'),
        input: { articleId, isPublished }
      });
    },

    updateArticleThumbnail: ({ article }) => {
      const { _id: articleId, thumbnail } = article;
      return handleDefaultUpdateApiCall({
        article,
        method: _updateArticleThumbnail,
        methodName: 'updateArticleThumbnail',
        hasRequiredParams: (articleId && thumbnail),
        input: { articleId, thumbnail }
      });
    },

    updateArticleTitle: ({ article }) => {
      const { _id: articleId, title } = article;
      return handleDefaultUpdateApiCall({
        article,
        method: _updateArticleTitle,
        methodName: 'updateArticleTitle',
        hasRequiredParams: (articleId && title),
        input: { articleId, title }
      });
    },

    updateArticleCategoryId: ({ article }) => {
      const { _id: articleId, categoryId } = article;
      return handleDefaultUpdateApiCall({
        article,
        method: _updateArticleCategoryId,
        methodName: 'updateArticleCategoryId',
        hasRequiredParams: (articleId && categoryId),
        input: { articleId, categoryId }
      });
    },

    updateArticleHTML: ({ article }) => {
      const { _id: articleId, html } = article;
      return handleDefaultUpdateApiCall({
        article,
        method: _updateArticleHTML,
        methodName: 'updateArticleHTML',
        hasRequiredParams: (articleId && html),
        input: { articleId, html }
      });
    },

    deleteArticle: ({ articleId, categoryName }) => new Promise((resolve, reject) => {
      const confirmWord = 'delete';
      let userInput;
      while (userInput !== confirmWord) {
        // eslint-disable-next-line no-alert
        userInput = prompt(`Delete article?\n\ntype '${confirmWord}' to confirm`);
        if (userInput === null) {
          resolve('delete action cancelled');
          return;
        }
      }
      _deleteArticle({
        variables: { input: { articleId } }
      }).then(({ data: { deleteArticle: { success } } }) => {
        if (!success) {
          reject(new Error('failed to update'));
          return;
        }
        dispatch({
          type: STORE_DELETE_ARTICLE,
          payload: { articleId, categoryName }
        });
        resolve({ success });
      }).catch(reject);
    })
  };
};

export default useArticleUtils;
