import useReduxStore from './useReduxStore';

const useArticle = ({ categoryName, articleId }) => {
  if (!categoryName) throw new Error('missing categoryName');
  if (!articleId) throw new Error('missing articleId');

  const { articles } = useReduxStore();
  const article = articles?.[categoryName.toLowerCase()]?.[articleId];

  return article;
};

export default useArticle;
