import { string } from 'prop-types';
import { useSelector } from 'react-redux';

const useCatalog = (options) => {
  const categoryName = options?.categoryName;
  const { articles, categories } = useSelector((state) => {
    // if a category name is specified, only return articles for that category
    const copy = { ...state };
    if (categoryName) {
      copy.articles = state.articles?.[categoryName.toLowerCase()] || null;
    }

    return copy;
  });

  return { articles, categories };
};

useCatalog.propTypes = {
  categoryName: string
};

useCatalog.defaultProps = {
  categoryName: ''
};

export default useCatalog;
