import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import Auth from '../../../utils/Auth';

import './style.scss';
import useAppConfig from '../../../hooks/useAppConfig';

const HeaderBrand = ({
  setSelectedTab,
  displayLoginForm,
  setDisplayLoginForm
}) => {
  const { brandText } = useAppConfig();
  const loggedIn = Auth.loggedIn();
  const [tapCount, setTapCount] = useState(0);

  const handleTap = () => {
    setTapCount((curr) => curr + 1);
  };

  // if double click on header, display admin login
  useEffect(() => {
    setTimeout(() => setTapCount(0), 600);
    setSelectedTab('/');

    if (loggedIn) return;

    if (displayLoginForm && tapCount === 1) {
      setDisplayLoginForm(!displayLoginForm);
    } else if (!displayLoginForm && tapCount === 3) {
      setDisplayLoginForm((curr) => !curr);
    }
  }, [tapCount]);

  return (
    <div className="brand">
      <h1>
        <Link to={loggedIn ? '/admin' : '/'} onClick={handleTap}>
          {brandText || ''}
        </Link>
      </h1>

      {loggedIn && (
        <Button
          id="admin-logout"
          type="submit"
          onClick={Auth.logout}
          variant="outline-danger"
          size="sm"
        >
          logout
        </Button>
      )}
    </div>
  );
};

HeaderBrand.propTypes = {
  setSelectedTab: func.isRequired,
  setDisplayLoginForm: func.isRequired,
  displayLoginForm: bool.isRequired
};

export default HeaderBrand;
