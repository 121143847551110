import React from 'react';
import { useSelector } from 'react-redux';
import ArticleTeaserListSideScroll from '../ArticleTeaserListSideScroll';

import './style.scss';

const ArticleRecentList = () => {
  const { recent } = useSelector((state) => state?.articles);
  const errorMessage = '[no articles available]';

  return (
    <div className="article-recent-list">
      <h2>recent</h2>
      <div className="teaser-list-wrapper">
        {!recent?.length ? (
          <h3 className="error">{errorMessage}</h3>
        ) : (
          <ArticleTeaserListSideScroll size="sm" teasers={recent.slice(0, 3)} />
        )}
      </div>
    </div>
  );
};

export default ArticleRecentList;
