/** -------------------------------------------------------------------------
 * this module tracks the editor state,
 * anytime the state changes a backup is created in local storage
 *
 */ //-----------------------------------------------------------------------
import { useEffect, useMemo, useState } from 'react';
import { getLocalStorageItem, LS_AUTO_SAVE_BLOG } from '../store_local';
import useArticleHtml from './useArticleHtml';
import placeholder from '../assets/placeholder.png';

export const EDITOR_VIEW = {
  EDITOR: {
    NAME: 'EDITOR'
  },
  PREVIEW: {
    NAME: 'PREVIEW'
  },
  PUBLISHED: {
    NAME: 'PUBLISHED'
  }
};

const useEditorState = ({ categoryName, articleId }) => {
  const { article, loading } = useArticleHtml({ categoryName, articleId });

  const lsKeyArticleTitle = useMemo(
    () => `${LS_AUTO_SAVE_BLOG}-title-${articleId}`,
    [articleId]
  );
  const lsKeyTempHtml = useMemo(
    () => `${LS_AUTO_SAVE_BLOG}-html-${articleId}`,
    [articleId]
  );

  const autoSavedTitle = getLocalStorageItem({ key: lsKeyArticleTitle });
  const autoSavedHtml = getLocalStorageItem({ key: lsKeyTempHtml });

  const [state, setState] = useState({
    ...article,
    title: autoSavedTitle,
    html: autoSavedHtml,
    publishChanges: null,
    view: EDITOR_VIEW.EDITOR.NAME
  });

  useEffect(() => {
    setState({
      ...article,
      ...state,
      thumbnail: {
        shape: article?.shape || 'look into this',
        url: article?.thumbnail.url || placeholder
      },
      title: autoSavedTitle || article?.title,
      html: autoSavedHtml || article?.html,
      hasLocalUnpublishedChanges: !!(autoSavedTitle || autoSavedHtml)
    });
  }, [article, loading]);

  return {
    state,
    setState,
    lsKeyArticleTitle,
    lsKeyTempHtml
  };
};

useEditorState.propTypes = {};

export default useEditorState;
