import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ImageEditor from '../../components/ImageEditor';
import RichTextEditor from '../../components/RichTextEditor';
import Spinner from '../../components/Spinner';
import useAppConfig from '../../hooks/useAppConfig';
import useAppConfigUtils from '../../hooks/useAppConfigUtils';
import Auth from '../../utils/Auth';

const AppConfigEditorPage = () => {
  const appConfig = useAppConfig();
  const {
    brandText,
    bioText,
    bioPic: {
      shape: bioPicShape,
      url: bioPicUrl
    }
  } = appConfig;
  const [formState, setFormState] = useState({ ...appConfig, html: bioText });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loggedIn = Auth.loggedIn();
  const {
    updateAppConfigBrandText,
    updateAppConfigBioText,
    updateAppConfigBioPic
  } = useAppConfigUtils();

  const handleInputChange = ({ target: { name, value } }) => {
    setFormState({ ...formState, [name]: value });
  };

  const setStagedState = (bioPic) => {
    setFormState({ ...formState, bioPic });
  };

  const handleSaveConfig = async (e) => {
    e.preventDefault();

    const promises = [];
    const shouldUpdateAppConfigBrandText = () => brandText !== formState.brandText;
    const shouldUpdateAppConfigBioText = () => bioText !== formState.html;
    const shouldUpdateAppConfigBioPic = () => bioPicShape !== formState.bioPic.shape
      || bioPicUrl !== formState.bioPic.url;

    setLoading(true);

    try {
      if (shouldUpdateAppConfigBrandText()) {
        promises.push(
          updateAppConfigBrandText(formState._id, formState.brandText)
        );
      }

      if (shouldUpdateAppConfigBioText()) {
        promises.push(
          updateAppConfigBioText(formState._id, formState.html)
        );
      }

      if (shouldUpdateAppConfigBioPic()) {
        promises.push(
          updateAppConfigBioPic(
            formState._id,
            formState.bioPic.shape,
            formState.bioPic.url
          )
        );
      }

      await Promise.allSettled(promises);
      navigate('/admin');
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!loggedIn) {
      navigate('/error');
    }
  }, []);

  if (!loggedIn) return null;
  if (!appConfig?.bioPic || !formState?.bioPic) return <Spinner size="lg" />;

  return (
    <div>
      <h2>Settings</h2>
      <Form onSubmit={handleSaveConfig}>
        <ImageEditor
          label="Bio Picture"
          stagedState={formState.bioPic}
          originalState={appConfig.bioPic}
          setStagedState={setStagedState}
        />

        <Form.Group as={Row} className="mb-3" controlId="formFirstName">
          <Form.Label sm={2}>Brand Text</Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              type="text"
              name="brandText"
              onChange={handleInputChange}
              value={formState.brandText}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formBio">
          <Form.Label sm={2}>Bio</Form.Label>
          <Col sm={12}>
            <RichTextEditor
              state={formState}
              setState={setFormState}
              lsKeyTempHtml={`autoSave-bio-${formState.appConfigId}`}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col className="d-grid gap-2 mb-3" sm={{ span: 12, offset: 0 }}>
            <Button type="submit" variant="outline-success">
              {loading ? <Spinner /> : 'Publish'}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default AppConfigEditorPage;
