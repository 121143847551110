import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GQL_GET_ARTICLE_HTML } from '../utils/queries';
import { STORE_UPDATE_ARTICLE } from '../store/articles/actions';
import useArticle from './useArticle';

const useArticleHtml = ({ categoryName, articleId }) => {
  const dispatch = useDispatch();
  const article = useArticle({ categoryName, articleId });

  const { data, loading, error } = useQuery(GQL_GET_ARTICLE_HTML, {
    variables: { articleId },
    skip: article?.html
  });

  const { html } = data?.getArticleHTML || {
    html: ''
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate('/404');
    }
  }, [article]);

  useEffect(() => {
    if (article && !article?.html) {
      article.html = html;

      dispatch({
        type: STORE_UPDATE_ARTICLE,
        payload: { categoryName, article }
      });
    }
  }, [data]);
  return { loading, article };
};

export default useArticleHtml;
