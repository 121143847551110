import React from 'react';
import { func, objectOf, string } from 'prop-types';
import HeaderLink from './HeaderLink';

import './style.scss';

const HeaderNav = ({ categories, selectedTab, setSelectedTab }) => (
  <nav>
    <ul>
      <HeaderLink
        to="/"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        home
      </HeaderLink>

      {Object.entries(categories).map(([_id, name]) => (
        <HeaderLink
          key={_id}
          to={`/${name.toLowerCase()}`}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        >
          {name}
        </HeaderLink>
      ))}
    </ul>
  </nav>
);

HeaderNav.propTypes = {
  categories: objectOf(string).isRequired,
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired
};

export default HeaderNav;
