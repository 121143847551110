import React from 'react';
import { func, string } from 'prop-types';
import { Link } from 'react-router-dom';

const HeaderLink = ({
  to, children, selectedTab, setSelectedTab
}) => {
  const determineUnderline = (name) => (selectedTab === name ? 'underline' : '');
  return (
    <li>
      <Link
        to={to}
        className={determineUnderline(to)}
        onClick={() => setSelectedTab(to)}
      >
        {children}
      </Link>
    </li>
  );
};

HeaderLink.propTypes = {
  to: string.isRequired,
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired,
  children: string.isRequired
};

export default HeaderLink;
