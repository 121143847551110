import {
  func, object, oneOfType, string
} from 'prop-types';
import React from 'react';
import { Form, Row } from 'react-bootstrap';
import Button from '../Button';

const FormGroupButton = ({
  children, onClick, size, variant
}) => (
  <Form.Group as={Row} className="mb-3">
    <Button
      size={size}
      type="button"
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Button>
  </Form.Group>
);

FormGroupButton.propTypes = {
  children: oneOfType([string, object]).isRequired,
  onClick: func.isRequired,
  size: string,
  variant: string
};

FormGroupButton.defaultProps = {
  size: 'sm',
  variant: 'outline-info'
};

export default FormGroupButton;
