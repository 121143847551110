import React from 'react';

import useAppConfig from '../../hooks/useAppConfig';
import SocialIcon from '../SocialIcon';
import './style.scss';

const Footer = () => {
  const { socialLinks } = useAppConfig();

  const icons = socialLinks?.map((icon) => (
    <SocialIcon key={icon.alt} icon={icon} />
  )) || '';

  return (
    <footer>
      <a href="/#home" className="copyright">
        &copy; 2022 | racquelfox.com
      </a>
      <div className="social-icon-wrapper">
        {icons}
      </div>
    </footer>
  );
};

export default Footer;
