import { gql } from '@apollo/client';

export const GQL_LOGIN_CONFIG = gql`
mutation Mutation($input: LoginAdminInput!) {
  loginAdmin(input: $input) {
    token
    admin {
      brandText
      email
    }
  }
}
`;

export const GQL_CREATE_CATEGORY = gql`
mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    category {
      _id
      name
    }
  }
}`;

export const GQL_DELETE_CATEGORY = gql`
mutation DeleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    success
  }
}
`;

export const GQL_CREATE_ARTICLE = gql`
mutation Mutation($input: CreateArticleInput!) {
  createArticle(input: $input) {
    article {
      _id
      categoryId
      createdAt
      html
      title
      thumbnail {
        shape
        url
      }
      isPublished
    }
  }
}
`;

export const GQL_UPDATE_ARTICLE_PUBLISHED = gql`
  mutation Mutation($input: UpdateArticlePublishedInput!) {
  updateArticlePublished(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_ARTICLE_TITLE = gql`
mutation Mutation($input: UpdateArticleTitleInput!) {
  updateArticleTitle(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_ARTICLE_HTML = gql`
mutation Mutation($input: UpdateArticleHTMLInput!) {
  updateArticleHTML(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_ARTICLE_THUMBNAIL = gql`
mutation Mutation($input: UpdateArticleThumbnailInput!) {
  updateArticleThumbnail(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_ARTICLE_CATEGORY_ID = gql`
mutation Mutation($input: UpdateArticleCategoryIdInput!) {
  updateArticleCategoryId(input: $input) {
    success
  }
}
`;

export const GQL_DELETE_ARTICLE = gql`
mutation Mutation($input: DeleteArticleInput!) {
  deleteArticle(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_APP_CONFIG_BRAND_TEXT = gql`
mutation Mutation($input: UpdateAppConfigBrandTextInput!) {
  updateAppConfigBrandText(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_APP_CONFIG_BIO_TEXT = gql`
mutation Mutation($input: UpdateAppConfigBioTextInput!) {
  updateAppConfigBioText(input: $input) {
    success
  }
}
`;

export const GQL_UPDATE_APP_CONFIG_BIO_PIC_URL = gql`
mutation Mutation($input: UpdateAppConfigBioPicInput!) {
  updateAppConfigBioPic(input: $input) {
    success
  }
}
`;
