/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { STORE_CREATE_CATEGORY, STORE_DELETE_CATEGORY } from '../store/categories/actions';
import { GQL_CREATE_CATEGORY, GQL_DELETE_CATEGORY } from '../utils/mutations';

const useCategoryUtils = () => {
  const dispatch = useDispatch();
  const [_createCategory] = useMutation(GQL_CREATE_CATEGORY);
  const [_deleteCategory] = useMutation(GQL_DELETE_CATEGORY);

  return {
    createCategory: async (stateValue, setStateValue, event = null) => {
      if (event) event.preventDefault();
      return new Promise((resolve, reject) => {
        if (!decodeURI(stateValue)) {
          reject(new Error('params must be legal URI format'));
          return;
        }

        _createCategory(
          { variables: { input: { name: decodeURI(stateValue) } } }
        ).then(({ data: { createCategory: { category } } }) => {
          if (!category._id || !category.name) reject(new Error('missing category id or name'));
          dispatch({ type: STORE_CREATE_CATEGORY, payload: { category } });
          if (setStateValue) setStateValue('');
          resolve({ category });
        }).catch(reject);
      });
    },

    updateCategoryName: async (name) => null,

    deleteCategory: async ({ _id, categoryName }) => new Promise((resolve, reject) => {
      if (!_id || !categoryName) {
        reject(new Promise('missing category id or name'));
        return;
      }

      let confirmName;

      while (confirmName !== categoryName) {
        confirmName = prompt(`enter '${categoryName}' to confirm delete`);
        if (confirmName === null) {
          resolve('cancelled delete');
          return;
        }
      }

      _deleteCategory({ variables: { input: { _id } } })
        .then(({ data: { deleteCategory: { success } } }) => {
          if (!success) reject(new Error('failed to update'));
          else {
            dispatch({ type: STORE_DELETE_CATEGORY, payload: { _id } });
            resolve({ success });
          }
        })
        .catch(reject);
    })
  };
};

export default useCategoryUtils;
