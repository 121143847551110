import { gql } from '@apollo/client';

export const GQL_GET_APP_CONFIG = gql`
  {
    appConfig {
      _id
      bioText
      email
      brandText
      socialLinks {
        alt
        href
        src
        isPublished
      }
      bioPic {
        shape
        url
      }
    }
  }
`;


export const GQL_GET_CATEGORIES = gql`
query getCategories {
  getCategories {
    _id
    name
  }
}
`;

export const GQL_GET_ARTICLES = gql`
query getArticles {
  getArticles {
    _id
    categoryId
    createdAt
    isPublished
    title
    thumbnail {
      shape
      url
    }
    updatedAt
  }
}
`;


export const GQL_GET_ARTICLE_HTML = gql`
query getArticleHTML($articleId: ID) {
  getArticleHTML(articleId: $articleId) {
    html
  }
}
`;
