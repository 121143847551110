import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GQL_GET_CATEGORIES } from '../utils/queries';
import { STORE_SET_CATEGORY } from '../store/categories/actions';

const useCategoryLoader = () => {
  const dispatch = useDispatch();

  const { data, loading: loadingCategories } = useQuery(GQL_GET_CATEGORIES);

  const categories = data?.getCategories || [];

  useEffect(() => {
    if (categories.length) {
      dispatch({
        type: STORE_SET_CATEGORY,
        payload: { categories }
      });
    }
  }, [data, loadingCategories]);

  return { loadingCategories };
};

export default useCategoryLoader;
