import { string } from 'prop-types';
import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import './style.scss';

const Spinner = ({ variant, size }) => (
  <div className="spinner-wrapper">
    <BootstrapSpinner animation="border" variant={variant} size={size} className="custom-theme" />
  </div>
);

Spinner.propTypes = {
  variant: string,
  size: string
};
Spinner.defaultProps = {
  variant: 'dark',
  size: 'sm'
};

export default Spinner;
