import { func } from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const ImagePicker = ({ setState }) => {
  const loadFileFromInput = ({ target }, cb) => {
    const file = target.files && target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => cb(reader.result);
  };

  const handleFileInput = (e) => {
    loadFileFromInput(e, (result) => setState(result));
  };

  return (
    <Form.Control
      type="file"
      className="custom-file-label"
      label="file input"
      accept="image/png, image/jpeg"
      onChange={handleFileInput}
    />
  );
};

ImagePicker.propTypes = {
  setState: func.isRequired
};

export default ImagePicker;
