// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import useAppConfig from '../../hooks/useAppConfig';
import RichTextRenderer from '../RichTextRenderer';
import './style.scss';

const AboutMe = () => {
  const { bioText, bioPic } = useAppConfig();

  return (
    <div className="about-me">
      <h2>about</h2>
      <RichTextRenderer
        bioPic={bioPic}
        text={bioText}
      />
    </div>
  );
};

export default AboutMe;
