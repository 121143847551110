import { string } from 'prop-types';
import React from 'react';
import Spinner from '../Spinner';
import './style.scss';

const Loading = ({ message }) => (
  <div className="loading">
    <Spinner size="lg" />
    {/* <h6>one moment</h6> */}
    {message ? <h6>{message}</h6> : ''}
  </div>
);

Loading.propTypes = {
  message: string
};

Loading.defaultProps = {
  message: ''
};

export default Loading;
