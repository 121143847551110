/**
 * data access pattern
 *
 * state.articles.[categoryName]
 *
 * state: {
 *    ...,
 *    articles: {
 *        [categoryName]: {
 *            [articleId]: {
 *              ...articleData
 *            }
 *            [articleId2]: {
 *              ...articleData
 *            }
 *         },
 *        [categoryName2]: {
 *            [articleId]: {
 *              ...articleData
 *            }
 *            [articleId2]: {
 *              ...articleData
 *            }
 *         }
 *    }
 * }
 *
 * */

import {
  STORE_SET_ARTICLES,
  STORE_CREATE_ARTICLE,
  STORE_UPDATE_ARTICLE,
  STORE_DELETE_ARTICLE
} from './actions';

const defaultState = { recent: [] };

const formatArticleAccessPattern = ({ articles, categories }) => (
  articles.reduce((object, article) => {
    const categoryName = categories[article.categoryId].toLowerCase();

    const updatedCategories = {
      ...object[categoryName],
      [article._id]: { ...article }
    };

    return {
      ...object,
      [categoryName]: { ...updatedCategories }
    };
  }, {})
);

// eslint-disable-next-line default-param-last
const articleReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case STORE_SET_ARTICLES: {
      const { articles, categories } = payload;
      const copy = [...payload.articles];

      const recent = copy
        .filter(({ isPublished }) => isPublished)
        .sort((a, b) => b.updatedAt - a.updatedAt);

      return {
        ...state,
        recent,
        ...formatArticleAccessPattern({ articles, categories })
      };
    }

    case STORE_CREATE_ARTICLE: {
      const { categoryName, article } = payload;
      const copy = { ...state };

      const formattedName = categoryName.toLowerCase();

      copy[formattedName] = {
        ...copy[formattedName],
        [article._id]: article
      };
      return copy;
    }

    case STORE_UPDATE_ARTICLE: {
      const { categoryName, article } = payload;
      return {
        ...state,
        [categoryName]: {
          ...state[categoryName],
          [article._id]: article
        }
      };
    }

    case STORE_DELETE_ARTICLE: {
      const { categoryName, articleId } = payload;
      const copy = { ...state[categoryName] };
      delete copy[articleId];

      return {
        ...state,
        [categoryName]: copy
      };
    }

    default:
      return state;
  }
};

export default articleReducer;
