import { sanitize } from 'dompurify';
import { shape, string } from 'prop-types';
import React from 'react';
import ImageRender from '../ImageRender';
import './style.scss';

const RichTextRenderer = ({ text, html, bioPic }) => (
  <div
    className="ql-editor"
  >

    {bioPic.url && <ImageRender image={bioPic} />}

    <div dangerouslySetInnerHTML={{
      __html: sanitize(text || html)
    }}
    />

  </div>
);

RichTextRenderer.propTypes = {
  bioPic: shape({ url: string, shape: string }),
  text: string,
  html: string
};

RichTextRenderer.defaultProps = {
  bioPic: {
    url: '',
    shape: ''
  },
  text: '',
  html: ''
};

export default RichTextRenderer;
